import { Placeholder, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Button, ButtonBlock, InlineError, Loader, RadioInput } from '../../../../atoms';
import sharedFormConstants from '../../../../constants/sharedFormConstants';
import { CardBlock, Error, Note, PaymentModal, Modal } from '../../../../molecules';
import { cloneDeep } from 'lodash';
import {
  formatDate,
  formatDateWithComma,
  formatDecimalWithCurrency,
  getLongLanguageCode,
  getServerUrl,
  isJsonString,
  formatDateWithoutUTC,
  TransactionCodeValue,
} from '../../../../services/utils';
import { NO_OF_BASIC_PERIOD } from '../../../../constants/product.constants';
import { useDispatch, useSelector } from 'react-redux';
import { setCheckoutTransitPage } from '../../../../redux/user/actions';
import { useLocation } from 'react-router-dom';
import api from '../../../../services/api';
import handleServerSuccessError from '../../../../services/handleServerSuccessError';
import './TTCCheckout0.scss';
import CreditCardImg from '../../../../assets/images/credit_card.png';
import AmericanExpress from '../../../../assets/images/Latest_American_Express.png';
import Interac from '../../../../assets/images/card_interac.png';
import MasterCard from '../../../../assets/images/mastercard_new.png';
import VisaCardImg from '../../../../assets/images/Visa_latest.png';
import Sears from '../../../../assets/images/Sears.png';
import MonerisVault from '../../../../assets/images/MonerisVault.png';
import MonerisToken from '../../../../assets/images/MonerisToken.png';
import Master from '../../../../assets/images/Master.png';
import Jcb from '../../../../assets/images/Jcb.png';
import Discover from '../../../../assets/images/Discover.png';
import Debit from '../../../../assets/images/Debit.png';
import Unipay from '../../../../assets/images/Unipay.png';

const TTCCheckout0 = ({
  ttcFields,
  state,
  setState,
  t,
  toNextStep,
  isError,
  rendering,
  product,
  set12MonthPass,
  userInfo,
  instance,
  pauseAndReinstate,
  setIsError,
  action,
  isSuccess,
  error,
  setIsModalOpen,
  autorenewSubscriptionList,
  transitPassfields,
}) => {
  const [isSavedPaymentSetForCustomer, setSavedPaymentSetForCustomer] = useState(false);
  const [isSavedPaymentSetForCustomerSelected, setSavedPaymentSetForCustomerSelected] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(false);
  const customerId = userInfo.customerId;
  const visibleId = userInfo.selectedCard.visibleId;
  const nickname = userInfo.selectedCard.nickName;
  //const [regFormOfPaymRecId, setRegFormOfPaymRecId] = useState('');
  const [isOrderCard, setIsOrderCard] = useState(false);
  const [token, setToken] = useState('');
  const configKeys = useSelector((s) => s.settings.configKeys);
  const location = useLocation();
  const returnUrl = `${getServerUrl()}${location.pathname}`;
  const dispatch = useDispatch();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isTTCModalOpen, setIsTTCModalOpen] = useState(false);
  const [discountResponse, setDiscountResponse] = useState('');
  const shoppingCart = useSelector((s) => s.user.shoppingCart);
  const autoLoadData = useSelector((redux) => redux.user.autoLoadData);
  const userName = userInfo.userName;
  const [isOpen, setIsOpen] = useState(false);
  const [isGetTicketFetching, setIsGetTicketFetching] = useState(false);
  const [showCard, setShowCard] = useState({ paymenMethodsList: [] });
  const [isGetTicketFetchError, setIsGetTicketFetchError] = useState('');
  const [autorenewSubscriptionError, setAutorenewSubscriptionError] = useState('');
  const [checkedCard,setCheckedCard] = useState("");
  const [processdone,setProcessdone] = useState(false);
  const radioStyle = {
    margin: '16px 0px 16px 0px',
  };
  function getTimePeriod(startDate, endDate) {
    return `${formatDate(startDate)} - ${endDate >= '1901' ? formatDate(endDate) : t('noEndDateText')
      }`;
  }
  const checkoutData = state.checkoutData
    ? state.checkoutData
    : {
      serviceProvider: state?.serviceProviderId,
      productId: state?.product?.ProductId,
      name: state?.product?.ProductName,
      subTotal: state?.product?.ProductPrice,
      validityStartDate: state?.product?.ValidityStartDate,
      validityEndDate: state?.product?.ValidityEndDate,
      autorenew: {
        ...state.autorenew,
      },
      flow: 'ttc',
    };

  const checkoutItem = {
    ...checkoutData,
    visibleId: userInfo.selectedCard.visibleId,
    autorenew: {
      ...checkoutData.autorenew,
      endDate:
        checkoutData.autorenew.year && checkoutData.autorenew.month
          ? `${checkoutData.autorenew.year}-${checkoutData.autorenew.month}-${new Date(
            checkoutData.autorenew.year,
            parseInt(checkoutData.autorenew.month, 10),
            0
          ).getDate()} 00:00:00Z`
          : null,
    },
  };

  const products = useSelector((state) => state.products);
  const logo = products?.logos?.TTC?.Logo;
  useEffect(() => {
    setIsLoading(true);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    api
      .getRegisterPaymentMeansForCustomer({
        CustomerId: customerId,
        VisibleId: visibleId,
      })
      .then((response) => {
        if (response.data?.Success) {
          if (response.data.RegisterPaymentMeansList[0]?.RegFormOfPaymRecId) {
            setSavedPaymentSetForCustomer(true);
            setShowCard({
              paymenMethodsList: response.data.RegisterPaymentMeansList,
            });
            
            //To pre-select radio button on pageload
            let registerPaymentMeansList = response.data.RegisterPaymentMeansList;
            let padInSPM = registerPaymentMeansList?.filter((key, index) => key.AccountType == 0);
            let cardInSPM = registerPaymentMeansList?.filter((key, index) => key.AccountType == 1);

            setIsLoading(false);
            //To pre-select radio button on pageload
            if (padInSPM.length > 0) {
              var id = ttcFields?.authorizedLabel.value;
              if (document.getElementById(id) != null) {
                document.getElementById(id).checked = true;
                setCheckedCard(id);
              }
              
              setState({
                ...state,
                selectedCard: sharedFormConstants.cardOptionSaved,
                regFormOfPaymRecId: padInSPM[0].RegFormOfPaymRecId,
              });

            }
            if (padInSPM.length == 0 && cardInSPM.length > 0) {
              var id = `**** ${cardInSPM[0].CreditCard.TruncatedPAN.slice(
                -4
              )}`;
              if (document.getElementById(id) != null) {
                document.getElementById(id).checked = true;
                setCheckedCard(id);
              }
              setState({
                ...state,
                selectedCard: sharedFormConstants.cardOptionSaved,
                regFormOfPaymRecId: cardInSPM[0].RegFormOfPaymRecId,
              });

            /*
            let id = `**** ${response.data.RegisterPaymentMeansList[0].CreditCard.TruncatedPAN.slice(
              -4
            )}`;
            if (document.getElementById(id) != null) {
              document.getElementById(id).checked = true;
            }
            setState({
              ...state,
              selectedCard: sharedFormConstants.cardOptionSaved,
              regFormOfPaymRecId: response.data.RegisterPaymentMeansList[0].RegFormOfPaymRecId,
            }); 
            */
          }
        } else {
            setIsLoading(false);
            let id = ttcFields?.AdhocPayment.value;
            document.getElementById(id).checked = true;
            setState({
              ...state,
              selectedCard: sharedFormConstants.cardOptionDebit,
            });
            setCheckedCard(id);
          }
        } else {
          setIsLoading(false);
        }
      });
    api
      .getAccessToken()
      .then((accessToken) => setToken(accessToken))
      .catch(() => {
        setToken('');
      });
  }, []);

  useEffect(() => {
    if(processdone)
    setCheckboxState(checkedCard);
    
},  [processdone]);

const setCheckboxState = (id) => {
    const element = document.getElementById(id);
    if (id && element) {
        element.checked = true;
    }
};

  const postForm = () => {
    document.getElementById('frmPayment').submit();
  };
  let pauseDate =
    autorenewSubscriptionList &&
    autorenewSubscriptionList.length > 0 &&
    autorenewSubscriptionList[0]?.MDPPauseDate;
  pauseDate = pauseDate && pauseDate.split('T');
  const formattedPauseDate = formatDateWithoutUTC(
    pauseDate && pauseDate.length > 0 && pauseDate[0]
  );

  let resumeDeadlineDate =
    autorenewSubscriptionList &&
    autorenewSubscriptionList.length > 0 &&
    autorenewSubscriptionList[0]?.MDPReinstateDeadline;
  resumeDeadlineDate = resumeDeadlineDate && resumeDeadlineDate.split('T');
  const formattedResumeDeadlineDate = formatDateWithoutUTC(
    resumeDeadlineDate && resumeDeadlineDate.length > 0 && resumeDeadlineDate[0]
  );
  let startDate =
    autorenewSubscriptionList &&
    autorenewSubscriptionList.length > 0 &&
    autorenewSubscriptionList[0]?.StartDateTime;
  const formattedStartDate = formatDateWithComma(startDate);

  let endDate =
    autorenewSubscriptionList &&
    autorenewSubscriptionList.length > 0 &&
    autorenewSubscriptionList[0]?.ExpiryDateTime;
  const formattedEndDate = formatDateWithComma(endDate);

  const getTermsAndConditions = () => {
    const rendering1 = cloneDeep(rendering);
    const rendering2 = cloneDeep(rendering);
    rendering1[0].placeholders['presto-terms-and-conditions'].splice(1, 1);
    rendering2[0].placeholders['presto-terms-and-conditions'].splice(0, 1);
    return state?.product?.ProductAttibutes?.NoOfBasicPeriod === NO_OF_BASIC_PERIOD
      ? rendering2[0]
      : rendering1[0];
  };

  const monerisEnvironment =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.Environment').map((config) => config.setting);

  const handleMoneris = () => {
    setIsOpen(true);
    setIsGetTicketFetching(true);
    setIsLoading(false);
    api
      .getTicket({
        paymentProfileType: 'TOKENIZE',
        OrderNumber: '',
        customerId: customerId,
        VisibleId: visibleId,
        userName: userName,
        Amount:
          product?.ProductFamily === 'MDP'
            ? state?.discountedProduct?.DiscountedPrice
            : state?.product?.ProductPrice,
      })
      .then((response) => {
        if (response.data.Success === true) {
          var myCheckout = new window.monerisCheckout();
          myCheckout.setMode(monerisEnvironment[0]);
          myCheckout.setCheckoutDiv('monerisCheckout');
          myCheckout.setCallback('page_loaded', PageLoad);
          myCheckout.setCallback('cancel_transaction', CancelTransaction);
          myCheckout.setCallback('error_event', ErrorEvent);
          myCheckout.setCallback('payment_receipt', PaymentReceipt);
          myCheckout.setCallback('payment_complete', PaymentComplete);
          myCheckout.startCheckout(response.data.TicketResponse.ticket);
          const ticketResponse = response.data.TicketResponse.ticket;

          function PageLoad() {
            setIsGetTicketFetching(false);
            setIsLoading(false);
          }

          function CancelTransaction() {
            setIsOpen(false);
            if(checkedCard && document.getElementById(checkedCard)){
              document.getElementById(checkedCard).checked = true;
            }
          }

          function PaymentComplete(args) {
            document.getElementById('monerisCheckout').innerHTML = '';
            document.getElementById('monerisCheckout').style = '';
            const parsedData = isJsonString(args) ? JSON.parse(args) : '';
            if (parsedData?.response_code !== '001') {
              setIsOpen(false);
              setAutorenewSubscriptionError(t('monerisPaymentError'));
              if(checkedCard && document.getElementById(checkedCard)){
                document.getElementById(checkedCard).checked = true;
              }
            } else {
              setIsOpen(false);
              setIsLoading(true);
              {
                pauseAndReinstate
                  ? api
                    .updateAutorenewSubscriptionByMoneris({
                      customerId: customerId,
                      visibleId: visibleId,
                      mediaId: userInfo.selectedCard.mediaId,
                      productId: state?.product?.ProductId,
                      //Year and month need to validate
                      year: checkoutData.autorenew.year,
                      month: checkoutData.autorenew.month,
                      deviceId: userInfo.sessionInstanceId,
                      ticket: ticketResponse,
                      userName: userName,
                      token: token,
                    })
                    .then((response) => {
                      if (response.data.Success === true) {
                        toNextStep();
                        setIsGetTicketFetching(false);
                        setIsLoading(false);
                      } else {
                        setIsGetTicketFetching(false);
                        setIsLoading(false);
                        const parsedError = isJsonString(response?.data?.Error)
                          ? JSON.parse(response.data.Error)
                          : '';
                        setAutorenewSubscriptionError(parsedError?.Description);
                        if(checkedCard && document.getElementById(checkedCard)){
                          document.getElementById(checkedCard).checked = true;
                        }
                      }
                    })
                  : api
                    .createAutorenewSubscription({
                      CustomerId: customerId,
                      VisibleId: visibleId,
                      MediaId: userInfo.selectedCard.mediaId,
                      ProductId: state?.product?.ProductId,
                      //Year and month need to validate
                      Year: state.autorenew.year,
                      Month: parseInt(state.autorenew.month),
                      DeviceId: userInfo.sessionInstanceId,
                      Ticket: ticketResponse,
                      Username: userName,
                    })
                    .then((response) => {
                      //console.log(response);
                      if (response.data.Success === true) {
                        toNextStep();
                        setIsGetTicketFetching(false);
                        // dispatch(setTtcCheckoutValues(response));
                        setIsLoading(false);
                        const transactionType = TransactionCodeValue(0);
                        const isoCode =
                          response.data?.CreatedSubscriptionInstance?.PaymentAgreement
                            ?.RegisterPaymentMeans[0]?.CreditCard?.ISOResponseCode;
                        const responseCode =
                          response.data?.CreatedSubscriptionInstance?.PaymentAgreement
                            ?.RegisterPaymentMeans[0]?.CreditCard?.ResponseCode;
                        const acceptanceStatus =
                          response.data?.CreatedSubscriptionInstance?.PaymentAgreement
                            ?.RegisterPaymentMeans[0]?.AcceptanceStatus;
                        const transactionNumber =
                          response.data?.CreatedSubscriptionInstance?.PaymentAgreement
                            ?.RegisterPaymentMeans[0]?.CreditCard?.CreditCardNumberRefRecId;
                        setState({
                          ...state,
                          showConfirmation: true,
                          confirmationNo: '',
                          TransactionType: transactionType,
                          ISOCode: isoCode,
                          ResponseCode: responseCode,
                          AcceptanceStatus: acceptanceStatus,
                          TransactionNumber: transactionNumber,
                        });
                      } else {
                        setIsGetTicketFetching(false);
                        setIsLoading(false);
                        const parsedError = isJsonString(response?.data?.Error)
                          ? JSON.parse(response.data.Error)
                          : '';
                        setAutorenewSubscriptionError(parsedError?.Description);
                        if(checkedCard && document.getElementById(checkedCard)){
                          document.getElementById(checkedCard).checked = true;
                        }
                      }
                    });
              }
            }
          }

          function PaymentReceipt(args) {
            PaymentComplete(args);
          }

          function ErrorEvent() {
            setIsOpen(false);
          }
        } else {
          setIsGetTicketFetching(false);
          setIsOpen(false);
          setIsLoading(false);
          const parsedError = isJsonString(response?.data?.Error)
            ? JSON.parse(response.data.Error)
            : '';
          setIsGetTicketFetchError(parsedError?.Description);
          if(checkedCard && document.getElementById(checkedCard)){
            document.getElementById(checkedCard).checked = true;
          }
        }
      })
      .catch(() => { }).finally(() => {
        setIsLoading(false);
        setProcessdone(true);
    });
  };

  const viewPaymentPlan = (productId) => {
    setIsLoading(true);
    api
      .getAutorenewPassDiscounts({
        productId,
        IsStubEnabled: false,
        IsErrorFlowEnabled: true,
      })
      .then((response) => {
        setIsLoading(false);
        setDiscountResponse(response?.data?.MDPDetails);
        setIsTTCModalOpen(true);
        setIsPaymentModalOpen(true);
      })
      .catch((error) => {
        setIsLoading(false);
        //console.log('error', error);
      });
  };

  const paymentClickHandler = (e) => {
    if (e.keyCode == 13) {
      viewPaymentPlan(product.ProductId);
    }
  };

  useEffect(() => {
    if (isPaymentModalOpen) {
      document.body.scrollTop = 0;
    }
  }, [isPaymentModalOpen]);

  const getCardImage = (Type) => {
    let cardImage;
    switch (Type.toLowerCase()) {
      case 0:
      case 'v':
      case 'visa':
        cardImage = VisaCardImg;
        break;
      case 1:
      case 'm':
      case 'mastercard':
        cardImage = MasterCard;
        break;
      case 2:
      case 'americanexpress':
        cardImage = AmericanExpress;
        break;
      case 3:
      case 'no':
      case 'discover':
        cardImage = Discover;
        break;
      case 4:
      case 'monerisvault':
        cardImage = MonerisVault;
        break;
      case 5:
      case 'moneristoken':
        cardImage = MonerisToken;
        break;
      case 6:
      case 'interac':
        cardImage = Interac;
        break;
      case 7:
      case 'master':
        cardImage = Master;
        break;
      case 8:
      case 'ax':
      case 'amex':
        cardImage = AmericanExpress;
        break;
      case 9:
      case 'unknown':
        cardImage = CreditCardImg;
        break;
      case 10:
        cardImage = CreditCardImg;
        break;
      case 11:
      case 'c1':
      case 'jcb':
        cardImage = Jcb;
        break;
      case 12:
      case 'd':
      case 'debit':
        cardImage = Interac;
        break;
      case 13:
      case 'se':
      case 'sears':
        cardImage = Sears;
        break;
      case 14:
      case 'up':
      case 'unionpay':
        cardImage = Unipay;
        break;
      default:
        cardImage = CreditCardImg;
    }
    return cardImage;
  };
  return [
    <>
      {isLoading && <Loader />}
      {isPaymentModalOpen ? (
        <div className="payment-plan-modal">
          <Modal
            title={transitPassfields.TTC12MonthMDPTitle?.value}
            text={
              <>
                <RichText field={transitPassfields.TTC12MonthMDPDescription} />
                <div className="payment-plan-wrapper">
                  <div className="payment-plan-header">
                    <Text field={transitPassfields.TTC12MonthMDPSubTitle} />
                  </div>
                  <div className="payment-plan-content">
                    <div className="payment-plan-subtitle">
                      <span className="ttcTerm">
                        <Text field={transitPassfields.TermMonth} />
                      </span>
                      <span className="ttcDiscount">
                        <Text field={transitPassfields.TTCPassDiscount} />
                      </span>
                      <span className="ttcPrice">
                        <Text field={transitPassfields.DiscountPrice} />
                      </span>
                    </div>
                    {discountResponse &&
                      discountResponse.length > 0 &&
                      discountResponse.map((data) => {
                        return (
                          <div className="payment-plan-data">
                            <span className="ttcTerm">{data.Term}</span>
                            <span className="ttcDiscount">{data.Discount}%</span>
                            <span className="ttcPrice">
                              {formatDecimalWithCurrency(data.DiscountedPrice)}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="payment-plan-button">
                  <Button
                    type="button"
                    onClick={() => {
                      setIsTTCModalOpen(false);
                      setIsPaymentModalOpen(false);
                    }}
                  >
                    {transitPassfields.CloseCTA?.value}
                  </Button>
                </div>
              </>
            }
            textSubmit={transitPassfields.CloseCTA?.value}
            isModalOpen={isTTCModalOpen}
            onSubmit={() => {
              setIsTTCModalOpen(false);
              setIsPaymentModalOpen(false);
            }}
            setIsModalOpen={setIsTTCModalOpen}
            hideButtons
            withoutFocusTrap={true}
          />
        </div>
      ) : null}
      <PaymentModal
        title={ttcFields?.paymentDetailsTitle.value}
        cautionMessage={t('monerisPaymentCautionMessage')}
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
        isGetTicketFetching={isGetTicketFetching}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      />
      {pauseAndReinstate && (
        <div className="pause-details-wrapper">
          <div className="pause-details-header">
            <Text field={ttcFields?.PauseDetailTitle} />
          </div>
          <div className="pause-details-descr">
            <RichText field={ttcFields?.PauseDetailDescription} />
          </div>
          <div className="pause-Details">
            <div className="pause-date">
              <span>
                <Text field={ttcFields?.PauseDate} />
              </span>
              <span className="bold-content">{formattedPauseDate}</span>
            </div>
            <div className="pause-deadline">
              <span>
                <Text field={ttcFields?.DeadLineLable} />
              </span>
              <span className="bold-content">{formattedResumeDeadlineDate}</span>
            </div>
            <div className="update-attempts">
              <span>
                <Text field={ttcFields?.UpdateAttemptsLabel} />
              </span>
              <span className="bold-content">
                {autorenewSubscriptionList &&
                  autorenewSubscriptionList.length > 0 &&
                  autorenewSubscriptionList[0]?.MDPPaymentRetryRemainingCounter}
              </span>
            </div>
          </div>
        </div>
      )}

      {!pauseAndReinstate && (
        <div className="card-nick-name">
          <span>
            <b>{nickname}</b>
          </span>
        </div>
      )}

      <div className="agencyOption-wrapper">
        <div className="agencyOption-header">
          <span>
            <Text field={ttcFields?.Item} />
          </span>
          {!pauseAndReinstate && (
            <span>
              <Text field={ttcFields?.priceLabel} />
            </span>
          )}
        </div>

        {!pauseAndReinstate ? (
          <div className="agencyOption-Pause-content">
            <div className="agencyOption-title-logo-block">
              {logo && logo.Src ? (
                <img alt={logo.Alt} className="agencyOption-title-logo" src={logo.Src} />
              ) : null}
            </div>
            <div className="agencyOption-title-block">
              <div>
                <div className="agencyOption-title">{state?.product?.ProductName}</div>
                <div className="agencyOption-details">
                  <div className="agencyOption-date">
                    {getTimePeriod(
                      state?.product?.ValidityStartDate,
                      state?.product?.ValidityEndDate
                    )}
                  </div>
                  {/* <div className="agencyOption-startdate">
                    <span>
                      <Text field={ttcFields?.StartDate} />{' '}
                    </span>
                    <span>{formattedStartDate}</span>
                  </div>
                  <div className="agencyOption-enddate">
                    <span>
                      <Text field={ttcFields?.EndDate} />{' '}
                    </span>
                    <span>{formattedEndDate}</span>
                  </div> */}
                  {/* <div className="agencyOption-discount">
                    <span>
                      <Text field={ttcFields?.AccumulatedDiscount} />{' '}
                    </span>
                    <span>
                      {autorenewSubscriptionList &&
                        autorenewSubscriptionList.length > 0 &&
                        autorenewSubscriptionList[0]?.MDPAccumulatedDiscount}
                    </span>
                  </div> */}
                </div>
                <div className="agencyOption-details">{ttcFields.TTC12MonthPassNote.value}</div>
              </div>
              {/* <a
                href="javascript:void(0)"
                className="pause-Link"
                onClick={(e) => {
                  e.preventDefault();
                  viewPaymentPlan(product.ProductId);
                }}
                onKeyDown={paymentClickHandler}
              >
                <Text field={ttcFields.ViewDiscountPlan} />
              </a> */}
            </div>
            <div className="agencyOption-value-block">
              {product?.ProductFamily === 'MDP' ? (
                <div className="agencyOption-value">
                  {formatDecimalWithCurrency(state?.discountedProduct?.DiscountedPrice)}
                </div>
              ) : (
                <div className="agencyOption-value">
                  {formatDecimalWithCurrency(state?.product?.ProductPrice)}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="agencyOption-content">
            <div className="agencyOption-title-logo-block">
              {logo && logo.Src ? (
                <img alt={logo.Alt} className="agencyOption-title-logo" src={logo.Src} />
              ) : null}
            </div>
            <div className="agencyOption-title-block">
              <div>
                <div className="agencyOption-title">{state?.product?.ProductName}</div>
                <div className="agencyOption-date">{getTimePeriod(startDate, endDate)}</div>
              </div>
              {state?.product?.ProductAttibutes?.NoOfBasicPeriod === NO_OF_BASIC_PERIOD ? (
                <div className="agencyOption-subtitle-block">{ttcFields.TtcPassNote.value}</div>
              ) : null}
            </div>
            <div className="agencyOption-value-block">
              {state?.product?.ProductPrice ? (
                <div className="agencyOption-value">
                  {formatDecimalWithCurrency(state?.product?.ProductPrice)}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
      {!pauseAndReinstate && (
        <div className="tcc-note">
          {state?.product?.ProductAttibutes?.NoOfBasicPeriod === NO_OF_BASIC_PERIOD ? (
            <div className="tcc-pass-note">
              <Note
                cssClass="note-top-margin"
                noteA11y={ttcFields.TTC12MonthPassDescriptionA11y.value}
              >
                <RichText field={ttcFields.TTC12MonthPassDescription} />
              </Note>
            </div>
          ) : null}
        </div>
      )}

      <Formik
        enableReinitialize
        initialValues={{
          [sharedFormConstants.terms]: false,
          [sharedFormConstants.cardOption]: isSavedPaymentSetForCustomer
            ? sharedFormConstants.cardOptionSaved
            : sharedFormConstants.cardOptionDebit,
          terms: false,
        }}
        validationSchema={Yup.object().shape({
          [sharedFormConstants.terms]: Yup.boolean().oneOf([true], t('termsAndConditionsRequired')),
        })}
        onSubmit={(values) => {
          setProcessdone(false);
          const newState = { ...state };
          if (newState.selectedCard === sharedFormConstants.cardOptionSaved) {
            setIsLoading(true);
            setSavedPaymentSetForCustomerSelected(true);
            if (pauseAndReinstate) {
              api
                .manageAutorenewSubscription({
                  VisibleId: userInfo.selectedCard.visibleId,
                  CustomerId: userInfo.customerId,
                  MediaId: userInfo.selectedCard.mediaId,
                  ProductId: state?.Product?.ProductId,
                  RegFormOfPaymRecId: state.regFormOfPaymRecId,
                  PaymentGatewayResponse: null,
                })
                .then((result) => {
                  //console.log(result);
                  if (result.data.Success) {
                    toNextStep();
                    setIsLoading(false);
                  } else {
                    handleServerSuccessError(result.data.Error, setIsError);
                    setIsLoading(false);
                  }
                })
                .catch((error) => {
                  //console.log(error);
                  setIsLoading(false);
                }).finally(() => {
                  setIsLoading(false);
                  setProcessdone(true);
              });
            } else {
              api
                .createSavedPaymentAutorenewSubscription({
                  VisibleId: userInfo.selectedCard.visibleId,
                  CustomerId: userInfo.customerId,
                  MediaId: userInfo.selectedCard.mediaId,
                  ProductId: state.product.ProductId,
                  RegFormOfPaymRecId: state.regFormOfPaymRecId,
                  PaymentGatewayResponse: null,
                  Month: state?.autorenew?.month,
                  Year: state?.autorenew?.year,
                })
                .then((response) => {
                  if (response.data.Success) {
                    const transactionType = TransactionCodeValue(0);
                    const isoCode =
                      response.data?.CreatedSubscriptionInstance?.PaymentAgreement
                        ?.RegisterPaymentMeans[0]?.CreditCard?.ISOResponseCode;
                    const responseCode =
                      response.data?.CreatedSubscriptionInstance?.PaymentAgreement
                        ?.RegisterPaymentMeans[0]?.CreditCard?.ResponseCode;
                    const acceptanceStatus =
                      response.data?.CreatedSubscriptionInstance?.PaymentAgreement
                        ?.RegisterPaymentMeans[0]?.AcceptanceStatus;
                    const transactionNumber =
                      response.data?.CreatedSubscriptionInstance?.PaymentAgreement
                        ?.RegisterPaymentMeans[0]?.CreditCard?.CreditCardNumberRefRecId;
                    setState({
                      ...state,
                      showConfirmation: true,
                      confirmationNo: '',
                      TransactionType: transactionType,
                      ISOCode: isoCode,
                      ResponseCode: responseCode,
                      AcceptanceStatus: acceptanceStatus,
                      TransactionNumber: transactionNumber,
                    });
                    toNextStep();
                    setIsLoading(false);
                  } else {
                    handleServerSuccessError(response.data.Error, setIsError);
                    setIsLoading(false);
                  }
                })
                .catch(() => {
                  setIsLoading(false);
                }).finally(() => {
                  setIsLoading(false);
                  setProcessdone(true);
              });
            }
          } else {
            handleMoneris();
          }
        }}
      >
        {({ errors, touched, handleSubmit, values }) => {
          const checkOption =
            values[sharedFormConstants.cardOption] === sharedFormConstants.cardOptionDebit;
          const savedCheckOption =
            values[sharedFormConstants.cardOption] === sharedFormConstants.cardOptionSaved;
          return (
            <form onSubmit={handleSubmit}>
              {isError ? (
                <Error
                  small
                  title={
                    isSavedPaymentSetForCustomerSelected
                      ? t('savedPaymentCouldNotBeProcessed')
                      : t('directPaymentCouldNotBeProcessed')
                  }
                />
              ) : null}
              {autorenewSubscriptionError ? (
                <Error small title={autorenewSubscriptionError} />
              ) : null}
              {isGetTicketFetchError ? <Error small title={isGetTicketFetchError} /> : null}

              <CardBlock title={ttcFields?.PaymentMethod.value}>
                <div
                  className="payment-text"
                  id="radio-group"
                  role="application"
                //aria-label={isOrderCard ? demo.value : demo.value}
                >
                  {/* <Text field={isOrderCard ? demo : demo} /> */}
                  <Text field={ttcFields?.PaymentMethodSelection} />
                </div>
                <div className="payment-radio">
                  <RadioInput
                    label={ttcFields?.AdhocPayment.value}
                    a11y={ttcFields?.AdhocPaymentA11y.value}
                    name={sharedFormConstants.cardOption}
                    value={sharedFormConstants.cardOptionDebit}
                    onChange={(e) => {
                      setState({ ...state, selectedCard: e.target.value });
                      setCheckedCard(e.target.id);
                    }}
                  />
                </div>
                {isSavedPaymentSetForCustomer && (
                  <div className="payment-radio">
                    {showCard &&
                      showCard.paymenMethodsList &&
                      showCard.paymenMethodsList.map((card, index) => {
                        if (card.AccountType === 1) {
                          return (
                            <div style={radioStyle}>
                              <RadioInput
                                label={`**** ${card.CreditCard.TruncatedPAN.slice(-4)}`}
                                a11y={`**** ${card.CreditCard.TruncatedPAN.slice(-4)}`}
                                name={sharedFormConstants.cardOption}
                                value={sharedFormConstants.cardOptionSaved}
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    selectedCard: e.target.value,
                                    regFormOfPaymRecId: card.RegFormOfPaymRecId,
                                  });
                                  setCheckedCard(e.target.id);
                                }}
                                cardImgSrc={getCardImage(card.CreditCard.CardType)}
                                cardImgAlt="Media Icon"
                                sm
                              />
                            </div>
                          );
                        }
                        if (card.AccountType === 0) {
                          return (
                            <div style={radioStyle}>
                              <RadioInput
                                label={ttcFields?.authorizedLabel.value}
                                a11y={ttcFields?.authorizedLabel.value}
                                name={sharedFormConstants.cardOption}
                                value={sharedFormConstants.cardOptionSaved}
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    selectedCard: e.target.value,
                                    regFormOfPaymRecId: card.RegFormOfPaymRecId,
                                  });
                                  setCheckedCard(e.target.id);
                                }}
                              />
                            </div>
                          );
                        }
                      })}
                  </div>
                )}
                <div className="checkout-terms-block">
                  <Placeholder
                    name="presto-terms-and-conditions"
                    rendering={getTermsAndConditions()}
                  />
                  {errors[sharedFormConstants.terms] && touched[sharedFormConstants.terms] && (
                    <InlineError cssClass="checkbox-error">
                      {errors[sharedFormConstants.terms]}
                    </InlineError>
                  )}
                </div>
                {!pauseAndReinstate && (
                  <div className="checkout-payment-total">
                    <span className="payment-Amount">
                      <Text field={ttcFields?.MonthlyPaymentFare} />
                    </span>
                    {product?.ProductFamily === 'MDP' ? (
                      <span>
                        {formatDecimalWithCurrency(state?.discountedProduct?.DiscountedPrice)}
                      </span>
                    ) : (
                      <span>{formatDecimalWithCurrency(product?.ProductPrice)}</span>
                    )}
                  </div>
                )}
              </CardBlock>
              {state.selectedCard === sharedFormConstants.cardOptionDebit ? (
                <div className="checkout-subtext">
                  <p>
                    <Text field={ttcFields?.redirectText} />
                  </p>
                </div>
              ) : null}
              <ButtonBlock className="ttc-Checkout-Button">
                <ButtonBlock right>
                  {state.selectedCard === sharedFormConstants.cardOptionDebit ? (
                    <Button type="submit">
                      <Text field={ttcFields?.nextButtonText} />
                    </Button>
                  ) : (
                    <Button type="submit" buttonTextA11y={ttcFields?.confirmButtonTextA11y}>
                      <Text field={ttcFields?.confirmButtonText} />
                    </Button>
                  )}
                  <Button
                    firstOrder
                    white
                    onClick={() => {
                      dispatch(setCheckoutTransitPage(false));
                      set12MonthPass(false);
                      setIsModalOpen(false);
                    }}
                  >
                    <Text field={ttcFields?.backButtonText} />
                  </Button>
                </ButtonBlock>
              </ButtonBlock>
            </form>
          );
        }}
      </Formik>
    </>,
    configKeys &&
    token &&
    configKeys
      .filter((item) => item.key === 'Cpg.Url')
      .map((configKey) => (
        <form
          method="POST"
          id="frmPayment"
          name="frmpayment"
          style={{ display: 'none' }}
          action={configKey.setting}
        >
          <input type="hidden" name="cc_crypt_type" value="7" id="inputCTID47" />
          <input type="hidden" name="cust_id" id="cust_id" value={userInfo.customerId} />
          <input type="hidden" name="rvarIsAx" value="true" id="inputCTID49" />
          <input type="hidden" name="rvarName" value="" id="inputCTID50" />
          <input type="hidden" name="lang" id="lang" value={getLongLanguageCode()} />
          <input type="hidden" name="LANGUAGE" id="LANGUAGE" value={getLongLanguageCode()} />
          <input type="hidden" name="paymentaggrement" id="paymentaggrement" value="" />
          <input type="hidden" name="nickname" id="nickname" value="" />
          <input
            type="hidden"
            name="ResponseURL"
            id="ResponseURL"
            value={`${getServerUrl()}/apidata/Autorenew/${pauseAndReinstate
              ? `UpdateAutorenewSubscriptionByMoneris`
              : `CreateAutorenewSubscription`
              }?visibleId=${userInfo.selectedCard.visibleId}&mediaId=${userInfo.selectedCard.mediaId
              }&token=${token}&productId=${checkoutData.productId}&year=${checkoutItem?.autorenew?.year
              }&month=${checkoutItem?.autorenew?.month
              }&returnUrl=${returnUrl}&serializedData=${encodeURI(
                JSON.stringify(checkoutData)
              )}&deviceId=${userInfo.sessionInstanceId}${pauseAndReinstate ? null : `&isTTCflow=true`
              }`}
          />
          <input type="hidden" name="isRegisteredPaymentMean" value="true" id="inputCTID56" />
        </form>
      )),
  ];
};

export default TTCCheckout0;
